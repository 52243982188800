<template>
  <section class="accordion-section">
    <header class="accordion-section__header" v-class-mod:accordion-section__header="{ isActive, hasOutline }">
      <icon-toggle-button
        v-if="!removeExpansion"
        :icon="isExpanded ? 'expand_more' : 'chevron_right'"
        :action="() => toggle()"
        :tooltip="{ title: translations.accordionSection[isExpanded ? 'collapse' : 'expand'] }"
        button-size="medium"
        class="accordion-section__toggle"
      />
      <slot name="header-before" v-bind="{ isExpanded }" />
      <span v-if="title" class="accordion-section__name" v-set-colors="{ color: titleColor }">
        <cut-text :text="title" />
      </span>

      <div class="accordion-section__addons" @click="() => toggle()">
        <template v-for="(icon, key) in icons || []" :key="key">
          <icon-wrapper
            v-if="
              !icon.disableWhen ||
              (icon.disableWhen === 'open' && !isExpanded) ||
              (icon.disableWhen === 'closed' && isExpanded)
            "
            v-bind="icon"
          />
        </template>

        <icon-indicator v-if="isNumber(amountIndicator)" :indicator="{ amount: amountIndicator }" />
      </div>
      <slot name="header-after" v-bind="{ isExpanded }" />

      <span
        class="accordion-section__link"
        tabindex="1"
        @click="
          () => {
            $emit('headerClick');
            if (!disableHeaderAction) {
              toggle();
            }
          }
        "
        @keydown.enter="() => toggle()"
      />
    </header>
    <section v-show="isExpanded" class="accordion-section__content">
      <slot v-if="hasBeenExpaneded" v-bind="{ isExpanded }">
        <empty-content
          v-if="!removeEmptyContent"
          :title="emptyContent.title"
          :explanation="emptyContent.explanation"
          :illustration="emptyContent.illustration"
        />
      </slot>
    </section>
  </section>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, watch } from "vue";

import { vSetColors } from "@horizon56/directives/set-colors";
import { ColorName } from "@horizon56/styles/types";
import { createId, H56Storage, isNumber } from "@horizon56/utils";

import { translations } from "@/infrastructure/translations";

import IconToggleButton from "@/components/buttons/icon-toggle-button.vue";
import { GroupExpansion } from "@/components/content/accordion-group.vue";
import CutText from "@/components/content/cut-text.vue";
import EmptyContent, { EmptyContentProps } from "@/components/content/empty-content.vue";
import IconIndicator from "@/components/icons/icon-indicator.vue";
import IconWrapper, { IconWrapperProps } from "@/components/icons/icon-wrapper.vue";

const getStorageKey = (id: string) => `H56AccordionSection${id}`;

const props = withDefaults(
  defineProps<{
    isOpen?: boolean;
    title?: string;
    isActive?: boolean;
    hasOutline?: boolean;
    titleColor?: ColorName;
    amountIndicator?: number;
    emptyContent?: EmptyContentProps;
    removeEmptyContent?: true;
    removeExpansion?: true;
    disableHeaderAction?: true;
    groupExpansion?: GroupExpansion;
    storage?: H56Storage;
    storageKey?: string;
    icons?: (IconWrapperProps & { disableWhen?: "open" | "closed" })[];
  }>(),
  {
    emptyContent: () => ({
      title: "No content",
      explanation: "There is no content available yet",
      illustration: "painter",
    }),
  },
);

const emits = defineEmits<{
  (e: "isExpanded", a: boolean): void;
  (e: "headerClick"): void;
  (e: "storageKey", a: string): void;
}>();

const sectionId = props.storageKey || createId();
if (props.storage) {
  emits("storageKey", sectionId);
}
const isExpanded = ref(props.isOpen);
const hasBeenExpaneded = ref(isExpanded.value);

const toggle = (newState = !isExpanded.value) => {
  isExpanded.value = newState;
  if (props.storage) {
    props.storage.save(getStorageKey(sectionId), isExpanded.value);
  }
};

defineExpose({ toggle });

watch(
  () => props.isOpen,
  () => {
    if (props.isOpen !== isExpanded.value) {
      isExpanded.value = props.isOpen;
    }
  },
);

const stop = watch(
  () => isExpanded.value,
  (is) => {
    if (is) {
      hasBeenExpaneded.value = true;
      stop();
    }
  },
);

watch(
  () => isExpanded.value,
  () => props.groupExpansion?.toggle?.(sectionId, isExpanded.value),
);

onMounted(async () => {
  if (props.storage) {
    const state = await props.storage.get(getStorageKey(sectionId));
    if (typeof state === "boolean") {
      isExpanded.value = state;
    }
  }
  props.groupExpansion?.mount(sectionId, isExpanded.value, toggle);
});

onBeforeUnmount(() => props.groupExpansion?.unmount(sectionId));
</script>

<style lang="scss" scoped>
.accordion-section {
  display: flex;
  flex-flow: column;
  display: flex;
  &__header,
  &__link {
    border-radius: var(--app-radius-small);
  }
  &__header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    min-height: var(--app-button-height-large);
    position: relative;
    user-select: none;
    z-index: 1;
    border: 2px solid transparent;
    column-gap: var(--app-spacing-size-xxsmall);
    padding-right: calc(var(--app-spacing-size-small) - 2px);
    padding-left: calc((var(--app-spacing-size-small) * var(--spacing, 0)) + var(--toggle-space, 0px) - 2px);
    &:hover {
      background: var(--menu-hover);
    }
    &--isActive {
      background: var(--menu-active);
    }
    &--hasOutline {
      border-color: var(--app-focus-color);
    }
  }
  &:not(:has(#{&}__toggle)) {
    --toggle-space: var(--app-button-height-medium);
  }
  &__link {
    position: absolute;
    z-index: -1;
    cursor: pointer;
    @include position-corner;
    @include focus-outline;
  }
  &__addons {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: var(--app-spacing-size-small);
  }
  &__children {
    margin-left: 32px;
  }
  &__name {
    user-select: none;
    pointer-events: none;
    margin-right: auto;
  }
}
</style>

<style lang="scss">
@for $i from 1 through 10 {
  $base: ".accordion-section";
  @for $x from 1 through $i {
    $base: $base + " .accordion-section";
  }
  #{$base} > .accordion-section__header {
    --spacing: #{$i};
  }
}
</style>
